/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 400px;
    width: 100%;
    position: relative; /* Important for close button positioning */
  }
  
  /* Close Button Styling */
  .modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: #888;
    cursor: pointer;
    font-weight: bold;
  }
  
  .modal-close-btn:hover {
    color: #512A74; /* Change color on hover */
  }
  
  .modal-box h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #512A74;
  }
  
  .modal-box form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .modal-box form select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal-box form button {
    background-color: #512A74;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .otp-buttons button {
    width: 45%;
    padding: 10px;
    background-color: #512A74;
    color: white;
    border: none;
    border-radius: 5px;
    margin: 10px 5px;
  }
  
  .modal-box p {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .modal-box strong {
    color: red;
  }
  
  .otp-buttons button:disabled {
    background-color: #ccc; /* Gray */
    color: #666; /* Darker text */
    cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
  }

  .redirect-whatsapp{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
  }

  .redirect-whatsapp p {
    margin: 0;
    line-height: 1;
    font-size: 20px;
    font-weight: bold;
  }

  .hr-with-text {
    display: flex;
    align-items: center;
  }
  
  .hr-with-text hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid #ccc;
    margin: 0;
  }
  
  .hr-with-text span {
    font-size: 16px;
    color: #666;
    font-weight: bold;
    white-space: nowrap;
  }
  