/* Default Sidebar Styling */
.sidebar {
  width: 300px;
  padding: 30px;
  background-color: #512A74; /* A slightly darker shade for better contrast */
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

/* Image Styling */
.sidebar img {
  width: 80%;
  max-width: 80%;
  height: auto;
  margin-bottom: 20px; /* Add some space below the image */
  border-radius: 8px; /* Slight rounding for image to match sidebar style */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow to give depth to the logo */
}

/* Heading Styling */
.sidebar h2 {
  margin-top: 20px;
  font-size: 24px; /* Slightly increase the font size */
  font-weight: 600; /* Add boldness */
  color: #fffffe; /* Use a gold shade for emphasis */
}

/* Paragraph Styling */
.sidebar p {
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.6;
  color: #ddd; /* Lighter text color for better readability on dark background */
}

/* Disclaimer Paragraph */
.sidebar .disclaimer {
  font-size: 13px;
  color: #bbb;
  margin-top: 20px;
  font-style: italic;
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
  .sidebar {
    width: 100%; /* Full width on small screens */
    padding: 20px; /* Adjust padding for smaller viewports */
    box-shadow: none; /* Remove shadow on small screens for simplicity */
    border-radius: 0; /* Remove rounding on smaller screens */
  }
}

@media (max-width: 480px) {
  .sidebar {
    padding: 15px; /* Further adjust padding for extra small screens */
  }

  .sidebar h2 {
    font-size: 20px; /* Decrease heading size on small screens */
  }

  .sidebar p {
    font-size: 14px; /* Decrease paragraph font size */
  }
}
