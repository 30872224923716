/* ChatBox.css */

/* General chat box styles */
.chat-box {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 100%;
  max-height: 70vh;
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  /* background-color: #fff; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  padding: auto 10px auto 10px;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2px;
  border-bottom: 1px solid #e0e0e0;
  /* background-color: #ffffff; */
  max-height: 80%;
}

/* Assistant and user message box styling */
.assistant-message, .user-message {
  padding: 12px 16px;
  border-radius: 12px;
  margin-bottom: 10px;
  max-width: 75%;
  word-wrap: break-word; /* Ensure long words or text wrap properly */
  word-break: break-word; /* Break words that are too long */
  line-height: 1.5;
  font-size: 14px;
  white-space: pre-wrap; /* Ensure that spaces and newlines are preserved */
}

/* Assistant messages */
.assistant-message {
  background-color: #f1f1f1;
  color: #333;
  text-align: left;
  align-self: flex-start;
}

/* Ordered and unordered list styling */
.assistant-message ol, .assistant-message ul {
  margin-left: 20px; /* Indent lists */
  padding-left: 20px; /* Add padding to keep list inside the box */
  line-height: 1;
}

/* List item styling */
.assistant-message ol li, .assistant-message ul li {
  word-wrap: break-word; /* Wrap text inside list items */
  margin-bottom: 4px;
  line-height: 1;
}

/* User messages */
.user-message {
  background-color: #daf0da; /* Light green for user messages */
  color: #333;
  text-align: right;
  align-self: flex-end; /* Position user messages to the right side */
  margin-left: auto;
}

/* Message labels (username or assistant) */
.assistant-label, .user-label {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 12px;
  color: #555;
}

/* Assistant label alignment */
.assistant-label {
  text-align: left;
  align-self: flex-start;
}

/* User label alignment */
.user-label {
  text-align: right;
  align-self: flex-end;
}

/* Spinner for loading indicator */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite; /* Create a spinning animation */
  display: inline-block;
   /* Space between spinner and "Thinking..." text */
  align-self: flex-end; /* Position user messages to the right side */
  margin-left: auto;
}

/* Keyframes for the spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Loading message styling */
.loading-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

/* Input section */
.chat-input {
  display: flex;
  gap: 10px;
  padding: 10px;
  /* background-color: #fff; */
  border-top: 1px solid #e0e0e0;
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
}

.chat-input input:disabled {
  background-color: #f1f1f1;
  cursor: not-allowed;
}

.chat-input button {
  padding: 10px 20px;
  background-color: #512A74;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.chat-input button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Helper to always scroll to the bottom */
div[ref="messagesEndRef"] {
  height: 1px;
}
