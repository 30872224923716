.container {
  margin: auto;
  width: 30%;
  display: flex;
  background-color: #5deb8c;
  align-items: center;
  justify-content: space-between; /* Space between icon and text */
  border-radius: 10px;
  padding: 20px;
  height: auto; /* Adjust height automatically */
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
  border: 1px solid #E2C1F4;
  margin-bottom: 20px;
  cursor: pointer;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.whatsapp-card-icon {
  height: 70px;
  width: 70px;
  margin-right: 10px;
}

.text-container {
  text-align: center; /* Align text to the left */
  flex: 1; /* Allow the text container to take available space */
}

.text {
  color: black;
}

.text-main {
  font-weight: bolder;
  font-size: 1.2rem; /* Adjust font size for emphasis */
}

.text-sub {
  font-weight: bold;
  font-size: 0.9rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .container {
    width: 90%; /* Adjust width to take up more space */
    flex-direction: column; /* Stack icon and text vertically */
    text-align: center;
    padding: 15px;
  }

  .whatsapp-card-icon {
    margin-bottom: 10px; /* Add space below the icon */
  }

  .text-container {
    text-align: center; /* Center-align the text */
  }

  .text-main {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .text-sub {
    font-size: 0.8rem;
  }
}
