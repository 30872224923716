/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #e0eafc, #cfdef3);
  color: #333;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  flex-direction: row; /* Default to row for larger screens */
}

/* Sidebar */
.sidebar {
  background-color: #512A74;
  color: #fff;
  width: 280px;
  padding: 40px 20px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Main Content */
.main-content {
  background-color: #fafafa;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  margin-left: 280px;
  padding: 20px;
  width: calc(100% - 280px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

/* Mobile Friendly Adjustments */
@media (max-width: 768px) {
  body {
    flex-direction: column; /* Stack elements vertically */
    align-items: stretch; /* Ensure elements take full width */
  }

  .sidebar {
    position: relative;
    width: 100%; /* Sidebar takes full width on mobile */
    height: auto;
    padding: 20px 10px;
    box-shadow: none; /* Remove shadow for a cleaner mobile look */
  }

  .main-content {
    margin-left: 0; /* No offset for mobile */
    width: 100%; /* Take up full width */
    padding: 15px; /* Adjust padding */
  }
}

/* Welcome Section */
.welcome-section {
  padding: 30px;
  width: 80%;
  max-width: 80%;
  margin: 20px auto 10px auto;
  text-align: center;
}

.welcome-section h2 {
  margin-bottom: 15px;
  color: #5e2d91;
  font-size: 22px;
  font-weight: 600;
}

.welcome-section p {
  color: #555;
  font-size: 14px;
  line-height: 1.6;
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .welcome-section {
    padding: 20px;
    width: 90%;
    max-width: 100%;
  }

  .welcome-section h2 {
    font-size: 20px;
  }

  .welcome-section p {
    font-size: 14px;
  }
}

/* Chat Section */
.chat-section {
  padding: 8px;
  width: 80%;
  max-width: 80%;
  margin: 10px auto;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Chat Box */
.chat-box {
  padding: 8px;
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* WhatsApp Button */
.whatsapp-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #25D366;
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

/* Tooltip Text */
.whatsapp-text {
  position: fixed;
  bottom: 100px;
  right: 40px;
  background-color: #512A74;
  color: #fff;
  padding: 8px 12px;
  border-radius: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 14px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.whatsapp-button:hover + .whatsapp-text {
  opacity: 1;
}

/* Disclaimer */
.final-disclaimer {
  font-size: 13px;
  color: #666;
  text-align: center;
  margin-top: 8px;
  line-height: 1.4;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .chat-box {
    height: calc(100vh - 100px); /* Adjust to prevent overflow on mobile */
    max-height: calc(100vh - 100px); /* Ensure it fits properly */
  }

  .chat-messages {
    max-height: calc(100vh - 160px); /* Reduce to fit input area */
  }

  .chat-input {
    padding: 10px;
    z-index: 10;
    position: sticky;
    bottom: 0;
  }
  
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
  }

  .main-content {
    margin-left: 0;
    width: 100%;
    padding: 20px 15px;
  }

  .welcome-section, .chat-box {
    max-width: 100%;
    padding: 20px;
  }

  .whatsapp-button {
    bottom: 20px;
    right: 20px;
  }

  .chat-section {
    padding: 8px;
    width: 90%;
    max-width: 90%;
    margin: 10px auto;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
